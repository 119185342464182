import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Men's`}</h2>
    <p>{`From: `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/malefashionadvice/comments/5da1dc/the_basic_bastard_basic_wardrobe_and_inspiration/"
      }}>{`Men's basics reddit post`}</a></p>
    <p>{`I'll be coming back to this to update but I figured it'd be good to keep it here:`}</p>
    <h3>{`Tops`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`plain crew-neck in white and gray`}<ul parentName="li" {...{
          "className": "contains-task-list"
        }}>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`1st shirt`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`2nd shirt`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`3rd shirt`}</li>
        </ul></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`oxford-cloth button-down in white and light blue`}</li>
    </ul>
    <h3>{`Bottoms`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`plain, dark, indigo, or black jeans`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`olive or caramel chinos`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`plain-front chino shorts (not cargo) -- khaki, olive, or navy`}<ul parentName="li" {...{
          "className": "contains-task-list"
        }}>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`1st pair`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`2nd pair`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`3rd pair`}</li>
        </ul></li>
    </ul>
    <h3>{`Layers (probably delayed till Fall)`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`crew-neck sweater in gray, oatmeal, navy, or maroon`}</li>
      <li parentName="ul">{`and/or `}{`[ ]`}{` crew-neck sweatshirt in gray, oatmeal, navy, or maroon`}</li>
    </ul>
    <h3>{`Outerwear`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`wool coat`}</li>
    </ul>
    <h3>{`Shoes`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`plain white sneakers`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`brown boots`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`sandals (my addition)`}</li>
    </ul>
    <h3>{`Accessories`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`plain mid-brown belt`}</li>
    </ul>
    <h2>{`Backpacking`}</h2>
    <h3>{`Brands:`}</h3>
    <ul>
      <li parentName="ul">{`Arc'teryx`}</li>
      <li parentName="ul">{`Rab`}</li>
      <li parentName="ul">{`Revolution Race`}</li>
      <li parentName="ul">{`Neptune Mountaneering`}</li>
      <li parentName="ul">{`Norrona`}</li>
    </ul>
    <h3>{`Resources:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.outdoorgearlab.com/"
        }}>{`https://www.outdoorgearlab.com/`}</a></li>
    </ul>
    <h3>{`Basics`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": false,
            "disabled": true
          }}></input>{` `}{`quick-dry antimicrobial underwear`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": false,
            "disabled": true
          }}></input>{` `}{`non-cotton socks`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": false,
            "disabled": true
          }}></input>{` `}{`synthetic quick-dry shirt`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": true,
            "disabled": true
          }}></input>{` `}{`pants (Revolution race)`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": true,
            "disabled": true
          }}></input>{` `}{`base layer bottom (Revolution race)`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": true,
            "disabled": true
          }}></input>{` `}{`hoodie (Revolution race)`}</p>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}>
        <p parentName="li"><input parentName="p" {...{
            "type": "checkbox",
            "checked": true,
            "disabled": true
          }}></input>{` `}{`base layer top (Revolution race)`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      